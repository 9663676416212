<template>
  <div>
    <v-card
      class="  me-0 pb-10"
      :class="$vuetify.breakpoint.smAndUp ? 'card-radius' : ''"
      color="#C3FFF5"
      flat
    >
    <v-row class="justify-end mx-6">
      <lang-swicther class="mt-3"/>
    </v-row>
    <v-card-title>
      <v-row class=" mx-0 mx-md-9">
        {{ $t('menu.menu') }}
        <v-spacer></v-spacer>
        <small class="font-weight-thin mr-4">
            {{ $t('menu.subTitle') }}
        </small>
      </v-row>
    </v-card-title>
      <v-card
      elevation="0"
        class="white d-flex align-center justify-start mx-3 mx-md-12 pe-6"
        style="overflow-x:auto;"
        max-height="109"
        min-height="109"
      >
              <div
                v-for="(item, index) in packageCategories"
                :key="index"
              >
                <v-btn
                  :class="curCategoryId === item._id ?  'secondary black--text' : '' "
                  class="ms-6"
                  @click="getMenuDetails(item)"
                  outlined>
                  {{ $i18n.locale === 'en' ? item.nameEn 
                  : item.nameAr }}
                </v-btn>
              </div>
      </v-card>
    <v-sheet class="secondary">
      
      <v-row :class="$vuetify.breakpoint.xs ?  'mt-4 mx-0' : 'mt-4 mx-12 '" >
        <v-col cols="6" md="4" lg="3" sm="6"  
          v-for="(item, index) in menuItems.menuItems" :key="index">
          <v-card
            flat
            :class="$vuetify.breakpoint.xs ? 
              'custom-menu mx-auto' : 'custom-menu'"
            max-height="280"
            min-height="280"
          >
            <v-img
              v-if="item.image"
              height="130"
              :src="baseUrl + item.image"
            ></v-img>
           <v-img
              v-else
              height="130"
              contain
              src="https://cdn.dribbble.com/users/1012566/screenshots/4187820/topic-2.jpg"

            ></v-img>

          <v-card-text class=" pa-2 pa-lg-4 ">
            <h5 class="primary--text text-long tooltip">
              {{ $i18n.locale === 'en' ?
                item.nameEn : item.nameAr }}
                <span class="tooltiptext">
                  {{ $i18n.locale === 'en' ?
                    item.nameEn : item.nameAr }}
                </span>
            </h5>
            <v-rating
              hover
              class="ms-n2"
              color="orange"
              readonly
              :value="item.averageRating ? item.averageRating.rating : 0 || 0"
              length="5"
              size="10"
            ></v-rating>
            <v-row class="pa-0 mx-0 mt-3" align="center" justify="center">
              <v-col cols="6" class="pa-0 ">
                <small class="me-2">Pro</small>
                <small class="font-weight-bold">{{ item.meanInfo ?  item.meanInfo.protein : '0.00' || '0.00' }} g</small>
              </v-col>
              <v-col cols="6" class="pa-0  ">
                <small class="me-2">Fat</small>
                <small class="font-weight-bold">{{ item.meanInfo ?  item.meanInfo.fat : '0.00' || '0.00' }} g</small>
              </v-col>
              <v-col cols="6" class="pa-0 ">
                <small class="me-2">Carb</small>
                <small class="font-weight-bold">{{ item.meanInfo ?  item.meanInfo.carbs : '0.00' || '0.00' }} g</small>
              </v-col>
              <v-col cols="6" class="pa-0 ">
                <small class="me-2">Cal</small>
                <small class="font-weight-bold">{{ item.meanInfo ?  item.meanInfo.calories : '0.00' || '0.00' }} <span>kcal</span></small>
              </v-col>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      btn_loading: false,
      // images: [
      //   require('../../assets/images/Rectangle 229.jpg'),
      //   require('../../assets/images/Rectangle 230.jpg'),
      //   require('../../assets/images/Rectangle 231.jpg'),
      //   require('../../assets/images/Rectangle 229.jpg'),
      //   require('../../assets/images/Rectangle 230.jpg'),
      //   require('../../assets/images/Rectangle 231.jpg'),
      //   require('../../assets/images/Rectangle 229.jpg'),
      //   require('../../assets/images/Rectangle 230.jpg')
      // ],
      packageCategories: [],
      menuItems: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      skip: 0,
      curCategoryId: null
    }
  },
  created(){
   if(!this.$store.getters.selectedPackage._id){
    this.$router.push({name:"Login"})
   }
  },
  apollo: {
    getPackageCategoryWiseMenu () {
      return {
        query: require("@/menu/api/queries/get_package_category_wise_menu.graphql"),
        variables: {
          packageId: this.selectedPackage._id
        },
        result({ data }) {
          // , loading, networkStatus, refetch
          this.packageCategories = data.getPackageCategoryWiseMenu
          if (this.packageCategories.length) this.getMenuDetails(this.packageCategories[0])
        }
      }
    }
  },
  computed: {
    ...mapGetters(['selectedPackage']),
  },
  components: {
    "lang-swicther": () => import("@/core/components/LangSwitch")
  },
  methods: {
    getMenuDetails (item) {
      this.curCategoryId =  item._id
          const arr = item.menuItem
          const ids = arr.map(o => o._id)
          const filtered = arr.filter(({_id}, index) => !ids.includes(_id, index + 1))
          this.menuItems = item.menuItem 
          this.menuItems.menuItems = filtered
    },
  }
}
</script>

<style scoped>
.custom-menu {
  border-radius: 20px !important;
}
.text-long {
  width: 15em; /* the element needs a fixed width (in px, em, %, etc) */
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; 
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 60%;
  left: 10%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>